import * as React from "react"
import team from "../../../images/au-coeur-de-greengo/team.png"
import "./Team.scss"

const Team: React.FunctionComponent = () => {
  return (
    <section className="team">
      <h1 className="heading heading--1 heading--svg heading--svg-right">
        Au coeur de Greengo
      </h1>
      <img src={team} alt="équipe" />
    </section>
  )
}

export default Team
