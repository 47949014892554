import * as React from "react"
import lucas from "../../../images/au-coeur-de-greengo/lucas.png"
import yasmine from "../../../images/au-coeur-de-greengo/yasmine.png"
import "./Entrepreneurs.scss"

const Entrepreneurs: React.FunctionComponent = () => {
  return (
    <section className="entrepreneurs padding">
      <article>
        <h3 className="paragraph paragraph--top">
          La Consigne GreenGo, c’est l’aventure de deux entrepreneurs
          passionnés, réunis par l’ambition de donner à chacun la possibilité
          d’agir sur sa planète.
        </h3>
        <div className="entrepreneurs__items">
          <div className="entrepreneurs__item">
            <div className="background background_yasmine">
              <img src={yasmine} alt="yasmine" />

              <h3 className="paragraph paragraph--highlight">Yasmine</h3>
            </div>
          </div>
          <div className="entrepreneurs__item">
            <div className="background  background_lucas">
              <img src={lucas} alt="lucas" />

              <h3 className="paragraph paragraph--highlight">Lucas</h3>
            </div>
          </div>
        </div>
        <p className="paragraph left-flask">
          Diplômée de Centrale Supelec en 2012, Yasmine débute sa vie
          professionnelle pendant 4 ans en tant qu’ingénieur pétrolier. Elle
          passe ensuite 2,5 ans chez McKinsey. Le déclic écologique qu’elle
          connaît et son envie d’entreprendre lui fait quitter ce monde des
          grandes entreprises.
        </p>

        <p className="paragraph">
          Au même moment, Lucas Graffan, diplômé de l’EM Lyon, quitte le grand
          groupe Procter & Gamble où il occupait le poste d’analyste financier
          depuis 4 ans. Après avoir passé 8 mois en Asie à la rencontre de 50
          fablabs, il rentre en France et décide de se dédier à la problématique
          de réduction des déchets.
        </p>
        <p className="paragraph right-flask">
          En mai 2018, par hasard, au détour d’une conférence ils se font
          introduire par une amie en commun qui avait senti le potentiel d’une
          telle rencontre entre Lucas et Yasmine. Et elle avait vu juste !
          Depuis ce jour, ils ne se sont plus quittés et ont travaillé dur pour
          conclure leur association en octobre 2018 lors de la signature de leur
          premier client biocoop où ils ont installé leur première solution de
          consigne digitale GreenGo en magasin.{" "}
        </p>
        <p className="paragraph">
          Yasmine rappelle très justement que « le meilleur déchet est celui que
          l’on ne crée pas ». Le système de la consigne fonctionnait très bien
          il y a plusieurs décennies, pourquoi ne pas la remettre au goût du
          jour en la modernisant ? Ils décident de s’attaquer en priorité à la
          restauration rapide, un mode de consommation qui génère énormément de
          déchets tous les jours, et qui fait de plus en plus d’adeptes chaque
          année.{" "}
        </p>

        <p className="paragraph">
          Très vite leurs premiers clients sont des grands comptes. Les
          cafétérias des entreprises développent en effet depuis plusieurs
          années le take away, car les habitudes alimentaires à la pause
          déjeuner changent (volonté de faire du sport le midi, d’aller faire
          ses courses pour optimiser son temps etc…). Les salariés sont de plus
          en plus nombreux à préférer déjeuner vite, et seuls.
        </p>
        <p className="paragraph">
          La Consigne GreenGo propose donc un système de consigne contemporain,
          qui permet au consommateur de ramener son emballage du déjeuner dans
          n’importe quel point de collecte du réseau GreenGo et de récupérer
          instantanément sa consigne sur son application mobile.
        </p>
        <p className="paragraph">
          Les possibilités de croissance sont multiples, tant le single use
          s’est immiscé partout dans nos vies : la cosmétique, les boissons, les
          lessives etc… Aujourd’hui et selon les fondateurs de la Consigne
          GreenGo, les marques et entreprises s’intéressent bien plus qu’avant à
          la responsabilité sociétale, le sujet devient même très stratégique!
        </p>
      </article>
    </section>
  )
}

export default Entrepreneurs
