import * as React from "react"
import "./Join.scss"

const Join: React.FunctionComponent = () => {
  return (
    <section className="join background--white">
      <div className="join__question">
        <h2>
          Vous avez envie de rejoindre l’aventure et d’intégrer une super équipe
          ?
        </h2>
      </div>
      <div className="join__link">
        <p>
          C’est{" "}
          <a href="https://jobs.makesense.org/?query=la%20consigne%20greengo&page=0&hitsPerPage=20&facets=%5B%22%2a%22,%22professions%22,%22contracts%22,%22sectors%22,%22projectSize%22,%22remote%22,%22legalForm%22,%22languages%22%5D&facetFilters=%5B%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D,%5B%5D%5D&restrictIndices=prod_JOBS&filters=status%3Aactive">
            par ici !
          </a>
        </p>
      </div>
    </section>
  )
}

export default Join
