import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import Entrepreneurs from "../components/pages/au-coeur-de-greengo/Entrepreneurs"
import Join from "../components/pages/au-coeur-de-greengo/Join"
import Team from "../components/pages/au-coeur-de-greengo/Team"
import SEO from "../components/Seo"
import "./au-coeur-de-greengo.scss"

const AuCoeurDeGreengo = () => (
  <Layout>
    <SEO
      title="Au coeur de GreenGo"
      description="Rencontrez notre équipe et venez nous rejoindre !"
      keywords={["équipe", "candidature", "offre", "consigne", "zéro déchets"]}
    />
    <div className="au-coeur-de-greengo__background">
      <NavBar activePage="au-coeur-de-greengo" />
      <Team />
      <Entrepreneurs />
      {/* <TeamMembers /> */}
      <Join />
      <Footer></Footer>
    </div>
  </Layout>
)

export default AuCoeurDeGreengo
